import React from 'react';
import { Icon, Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { formatNumber } from '../utils';

type Props = {
  count?: number;
  name: string;
  hash?: string;
};

const TableCount = ({ count, name, hash }: Props) => {
  const displayName = count === 1 ? name.slice(0, -1) : name;
  return (
    <span>
      {count === undefined ? (
        <>
          <Icon type="loading" /> {`Calculating total ${name}`}
        </>
      ) : hash ? (
        <span>
          {`${formatNumber(count, 'integer')} ${displayName} found with tx hash `}
          <Typography.Text copyable={{ text: hash }}>{`${hash.slice(0, 9)}...`}</Typography.Text>{' '}
          <Button type="primary" size="small">
            <Link to="/transfers">Reset</Link>
          </Button>
        </span>
      ) : (
        `${formatNumber(count, 'integer')} ${displayName}`
      )}
    </span>
  );
};

export default TableCount;
