import React from 'react';
import { Result, Button } from 'antd';

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<any, State> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Result
          status="warning"
          title="Sorry, something went wrong. Please contact support@chainalysis.com if the problem persists."
          extra={(
            <Button href="/" type="primary">
              Go Home
            </Button>
          )}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
