import React from 'react';
import { Button, Icon } from 'antd';

type Props = {
  selectedRows: Array<any>;
  name: string;
  handleRowSelectChange?: (arr: Array<any>, arr2: Array<any>) => void
};

const ActionPanel = ({ selectedRows, name, handleRowSelectChange }: Props) => {
  localStorage.setItem('reactorGraph', JSON.stringify(selectedRows));
  return selectedRows.length === 0 ? null : (
    <div css={{ position: 'fixed', bottom: '6rem', left: '50%', zIndex: 999 }}>
      <div
        css={{
          left: '-50%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#fff',
          padding: '1.25rem 2rem',
          width: '30rem',
          borderRadius: '4px',
          boxShadow: '0 4px 12px 0 rgba(0, 0, 0, 0.15)'
        }}
      >
        <span>
          <Icon type="share-alt" />{' '}
          {selectedRows.length === 1 ? `1 ${name.slice(0, -1)} selected` : `${selectedRows.length} ${name} selected`}
        </span>
        <Button target="_blank" href="/users/custom-open-in-reactor-graph-dd5c49/graphs" type="primary">
          Open in Reactor
        </Button>
        {handleRowSelectChange
          && (
            <Button
              css={{
                border: 0,
                position: 'absolute',
                top: 0,
                right: 0,
                fontSize: '13px',
                color: 'rgba(0, 0, 0, 0.65)'
              }}
              type="link"
              shape="circle"
              icon="close"
              onClick={() => handleRowSelectChange([], [])}
            />
          )}
      </div>
    </div>
  );
};

export default ActionPanel;
