import React, { ReactNode } from 'react';
import { Card } from 'antd';

type Props = {
  children?: ReactNode;
  title: string | ReactNode;
  isLoading?: boolean;
  actionButton?: ReactNode;
  placeholder?: ReactNode;
  additionalContent?: Function;
  noPadding?: boolean;
  noPaddingWithDivider?: boolean;
  isTabbedPanel?: boolean;
  table?: any
};

const Panel = React.memo(({
  children,
  title,
  isLoading,
  actionButton,
  placeholder = null,
  noPadding = false,
  noPaddingWithDivider = false,
  additionalContent,
  table,
  isTabbedPanel,
}: Props) => (
  <>
    <Card
      style={{
        ...(table && { borderRadius: 0 }),
        ...(isTabbedPanel && { borderTop: 0 }),
      }}
      bodyStyle={{
        ...(noPadding && { padding: 0 }),
        ...(noPaddingWithDivider && { padding: '1px 0 0' }),
      }}
      headStyle={{
        ...{ fontSize: '18px' },
        ...(noPaddingWithDivider && { padding: '0 1rem' }),
      }}
      title={title}
      extra={actionButton && actionButton}
    >
      {placeholder ? (isLoading ? placeholder : children) : children}
    </Card>
    {additionalContent && additionalContent()}
  </>
)) as React.ComponentType<Props>;

export default Panel;
