import React from 'react';
import { Button } from 'antd';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Spacer, Sizes } from '../utils/styled/ui';

type PaginationButtonStyle = 'arrows' | 'text';

export type PaginationBarProps = {
  offset: number;
  pageLimit: number;
  onChange: (page: number) => void;
  hasMore: boolean;
  buttonStyle?: PaginationButtonStyle;
};


const PaginationBar = ({ offset, pageLimit, onChange, hasMore, buttonStyle = 'text' }: PaginationBarProps) => (
  <div css={{ textAlign: 'right' }}>
    <Button
      onClick={() => onChange(offset - pageLimit >= 0 ? offset - pageLimit : 0)}
      disabled={!offset || offset === 0}
      icon={buttonStyle === 'arrows' ? 'left' : undefined}
      shape={buttonStyle === 'arrows' ? 'circle-outline' : undefined}
    >{buttonStyle === 'text' && 'Previous'}
    </Button>
    <Spacer spaceDirection="horizontal" size={Sizes.small} />
    <Button
      onClick={() => onChange(offset ? offset + pageLimit : pageLimit)}
      disabled={!hasMore}
      icon={buttonStyle === 'arrows' ? 'right' : undefined}
      shape={buttonStyle === 'arrows' ? 'circle-outline' : undefined}
    >{buttonStyle === 'text' && 'Next'}
    </Button>
  </div>
);

export default PaginationBar;
