// import moment from 'moment';
// import queryString from 'query-string';
// import filter from 'lodash/filter';
// import { breakpoints, primaryColor, dangerColor } from './constants';

// export const mq = size => `@media (max-width: ${breakpoints[size]}px)`;

// export const eventIconMap = {
//   Flagged: 'exception',
//   'In Review': 'file-search',
//   'No Review': 'close',
//   Unreviewed: 'file-unknown',
//   Dismissed: 'file-done',
//   Triggered: 'warning',
//   All: 'warning',
//   Comment: 'message'
// };

// export const scoreColorMap = {
//   red: 'red',
//   amber: 'yellow',
//   green: 'green'
// };

// export const colorStatusMap = {
//   red: 'error',
//   amber: 'warning',
//   green: 'success'
// };

// export const statusValueToText = {
//   OPEN: 'Open',
//   CLOSED: 'Closed'
// };

// export const severityMap = {
//   SEVERE: {
//     color: '#a20907',
//     background: '#fff0f0'
//   },
//   HIGH: {
//     color: '#c24e00',
//     background: '#ffece0'
//   },
//   MEDIUM: {
//     color: '#996b0f',
//     background: '#fff9db'
//   },
//   LOW: {
//     color: '#647382',
//     background: '#f7f7f7'
//   }
// };

// export const riskScoreOptions = [
//   {
//     text: 'High',
//     value: 'red',
//     key: 'red'
//   },
//   {
//     text: 'Medium',
//     value: 'amber',
//     key: 'amber'
//   },
//   {
//     text: 'Low',
//     value: 'green',
//     key: 'green'
//   }
// ];

// export const alertOptions = [
//   {
//     text: 'Severe',
//     value: 'SEVERE',
//     key: 'SEVERE'
//   },
//   {
//     text: 'High',
//     value: 'HIGH',
//     key: 'HIGH'
//   },
//   {
//     text: 'Medium',
//     value: 'MEDIUM',
//     key: 'MEDIUM'
//   },
//   {
//     text: 'Low',
//     value: 'LOW',
//     key: 'LOW'
//   }
// ];

// export const getAlertText = level => alertOptions.find(severity => severity.key === level).text;

// export const exposureOptions = [
//   { key: 'DIRECT', value: 'DIRECT', text: 'Direct' },
//   { key: 'INDIRECT', value: 'INDIRECT', text: 'Indirect' }
// ];
// export const directionOptions = [
//   { key: 'RECEIVED', value: 'RECEIVED', text: 'Received' },
//   { key: 'SENT', value: 'SENT', text: 'Sent' }
// ];

// export const directionMap = {
//   RECEIVED: 'Received',
//   SENT: 'Sent'
// };

// export const assetOptions = [
//   { key: 'BTC', value: 'BTC', text: 'Bitcoin (BTC)' },
//   { key: 'ETH', value: 'ETH', text: 'Ethereum (ETH)' },
//   { key: 'BCH', value: 'BCH', text: 'Bitcoin Cash (BCH)' },
//   { key: 'LTC', value: 'LTC', text: 'Litecoin (LTC)' },
//   { key: 'AUDX', value: 'AUDX', text: 'Etoro AUD' },
//   { key: 'BAT', value: 'BAT', text: 'Basic Attention Token (BAT)' },
//   { key: 'BNB', value: 'BNB', text: 'Binance Coin (BNB)' },
//   { key: 'BUSD', value: 'BUSD', text: 'Binance USD' },
//   { key: 'CADX', value: 'CADX', text: 'Etoro CAD' },
//   { key: 'CHFX', value: 'CHFX', text: 'Etoro CHF' },
//   { key: 'CNYX', value: 'CNYX', text: 'Etoro CNY' },
//   { key: 'CRO', value: 'CRO', text: 'Crypto.com (CRO)' },
//   { key: 'CRPT', value: 'CRPT', text: 'Crypterium' },
//   { key: 'DAI', value: 'DAI', text: 'DAI' },
//   { key: 'EURX', value: 'EURX', text: 'Etoro EUR' },
//   { key: 'GBPX', value: 'GBPX', text: 'Etoro GBP' },
//   { key: 'GLDX', value: 'GLDX', text: 'Etoro GLD' },
//   { key: 'GNO', value: 'GNO', text: 'Gnosis' },
//   { key: 'GUSD', value: 'GUSD', text: 'Gemini Dollar (GUSD)' },
//   { key: 'HOLD', value: 'HOLD', text: 'Hold' },
//   { key: 'HT', value: 'HT', text: 'Huobi Token' },
//   { key: 'HUSD', value: 'HUSD', text: 'Hot USD' },
//   { key: 'JPYX', value: 'JPYX', text: 'Etoro YEN' },
//   { key: 'LEO', value: 'LEO', text: 'UNUS SED LEO' },
//   { key: 'LINK', value: 'LINK', text: 'Chainlink' },
//   { key: 'MCO', value: 'MCO', text: 'Crypto.com (MCO)' },
//   { key: 'MLN', value: 'MLN', text: 'Melon' },
//   { key: 'NZDX', value: 'NZDX', text: 'Etoro NZD' },
//   { key: 'OMG', value: 'OMG', text: 'OmiseGO (OMG)' },
//   { key: 'PAX', value: 'PAX', text: 'Paxos Standard Token (PAX)' },
//   { key: 'PAXG', value: 'PAXG', text: 'Paxos Gold' },
//   { key: 'REP', value: 'REP', text: 'Augur' },
//   { key: 'RUBX', value: 'RUBX', text: 'Etoro RUB' },
//   { key: 'SLVX', value: 'SLVX', text: 'Etoro SLV' },
//   { key: 'TGBP', value: 'TGBP', text: 'TrueGBP' },
//   { key: 'TUSD', value: 'TUSD', text: 'TrueUSD (TUSD)' },
//   { key: 'USDC', value: 'USDC', text: 'USD Coin (USDC)' },
//   { key: 'USDEX', value: 'USDEX', text: 'Etoro USD' },
//   { key: 'USDT', value: 'USDT', text: 'Tether (USDT)' },
//   { key: 'USDT_ETH', value: 'USDT_ETH', text: 'USD Tether Ether (USDT_ETH)' },
//   { key: 'WETH', value: 'WETH', text: 'Wrapped Ether' },
//   { key: 'ZIL', value: 'ZIL', text: 'Ziliqa' },
//   { key: 'ZRX', value: 'ZRX', text: '0x (ZRX)' }
// ];

// // eslint-disable-next-line max-len
// export const isAccountBased = asset =>
//   [
//     'LINK',
//     'CRPT',
//     'MCO',
//     'CRO',
//     'GNO',
//     'HT',
//     'MLN',
//     'LEO',
//     'WETH',
//     'ZIL',
//     'TGBP',
//     'REP',
//     'HOLD',
//     'AUDX',
//     'CADX',
//     'CHFX',
//     'CNYX',
//     'EURX',
//     'GBPX',
//     'GLDX',
//     'NZDX',
//     'RUBX',
//     'SLVX',
//     'USDEX',
//     'JPYX',
//     'HUSD',
//     'PAXG',
//     'BUSD',
//     'BAT',
//     'BNB',
//     'DAI',
//     'ETH',
//     'GUSD',
//     'OMG',
//     'PAX',
//     'USDT',
//     'TUSD',
//     'USDC',
//     'USDT_ETH',
//     'ZRX'
//   ].includes(asset);

// export const getAssets = assets => filter(assetOptions, asset => assets.indexOf(asset.key) > -1);

// const categories = {
//   atm: { color: '#FADF86', text: 'ATM' },
//   calculating: { color: '#b1bec5', text: 'Calculating' },
//   'child abuse material': { color: '#ff1c46', text: 'Child Abuse Material' },
//   'coin generation': { color: '#185e20', text: 'Coin Generation' },
//   'custom address': { color: '#009688', text: 'Custom Address' },
//   'darknet market': { color: '#e43b38', text: 'Darknet Market' },
//   dust: { color: '#8fa4ad', text: 'Dust' },
//   'ethereum contract': { color: '#6bf0af', text: 'Ethereum Contract' },
//   'erc20 token': { color: '#00e675', text: 'ERC20 Token' },
//   'tor market': { color: '#e53935', text: 'Tor Market' },
//   ransomware: { color: '#8b1b1b', text: 'Ransomware' },
//   'terrorist financing': { color: '#421313', text: 'Terrorist Financing' },
//   scam: { color: '#4f3731', text: 'Scam' },
//   gambling: { color: '#ff5827', text: 'Gambling' },
//   'high risk exchange': { color: '#880d4f', text: 'High Risk Exchange' },
//   ico: { color: '#c4c9ea', text: 'ICO' },
//   mixing: { color: '#e82463', text: 'Mixing' },
//   exchange: { color: '#5c6bbe', text: 'Exchange' },
//   'p2p exchange': { color: '#4a148c', text: 'P2P Exchange' },
//   'personal wallet': { color: '#42a5f5', text: 'Personal Wallet' },
//   'hosted wallet': { color: '#1f75d0', text: 'Hosted Wallet' },
//   'merchant services': { color: '#ffbf10', text: 'Merchant Services' },
//   'mining pool': { color: '#4ead51', text: 'Mining Pool' },
//   mining: { color: '#4ead51', text: 'Mining' },
//   other: { color: '#009688', text: 'Other' },
//   sanctions: { color: '#e43b38', text: 'Sanctions' },
//   unknown: { color: '#cccccc', text: 'Unknown' },
//   uncategorized: { color: '#009688', text: 'Uncategorized' },
//   unidentified: { color: '#009688', text: 'Unidentified' },
//   'unnamed service': { color: '#455a63', text: 'Unnamed Service' },
//   unspent: { color: '#617d8b', text: 'Unspent' },
//   address: { color: '#607d8b', text: 'Address' },
//   'stolen funds': { color: '#79564a', text: 'Stolen Funds' }
// };

// export const categoryOptions = [
//   { value: 'atm', key: 'atm', text: 'ATM' },
//   { value: 'child abuse material', key: 'child abuse material', text: 'Child Abuse Material' },
//   { value: 'darknet market', key: 'darknet market', text: 'Darknet Market' },
//   { value: 'erc20 token', key: 'erc20 token', text: 'ERC20 Token' },
//   { value: 'ethereum contract', key: 'ethereum contract', text: 'Ethereum Contract' },
//   { value: 'exchange', key: 'exchange', text: 'Exchange' },
//   { value: 'gambling', key: 'gambling', text: 'Gambling' },
//   { value: 'high risk exchange', key: 'high risk exchange', text: 'High Risk Exchange' },
//   { value: 'hosted wallet', key: 'hosted wallet', text: 'Hosted Wallet' },
//   { value: 'ico', key: 'ico', text: 'ICO' },
//   { value: 'merchant services', key: 'merchant services', text: 'Merchant Services' },
//   { value: 'mining', key: 'mining', text: 'Mining' },
//   { value: 'mining pool', key: 'mining pool', text: 'Mining Pool' },
//   { value: 'mixing', key: 'mixing', text: 'Mixing' },
//   { value: 'other', key: 'other', text: 'Other' },
//   { value: 'p2p exchange', key: 'p2p exchange', text: 'P2P Exchange' },
//   { value: 'ransomware', key: 'ransomware', text: 'Ransomware' },
//   { value: 'sanctions', key: 'sanctions', text: 'Sanctions' },
//   { value: 'scam', key: 'scam', text: 'Scam' },
//   { value: 'stolen funds', key: 'stolen funds', text: 'Stolen Funds' },
//   { value: 'stolen bitcoins', key: 'stolen bitcoins', text: 'Stolen Bitcoins' },
//   { value: 'stolen ether', key: 'stolen ether', text: 'Stolen Ether' },
//   { value: 'terrorist financing', key: 'terrorist financing', text: 'Terrorist Financing' },
//   { value: 'unnamed service', key: 'unnamed service', text: 'Unnamed Service' },
//   { value: '_empty_', key: '_empty_', text: 'Unidentified' }
// ];

// const alertCategories = [
//   'child abuse material',
//   'custom address',
//   'darknet market',
//   'gambling',
//   'high risk exchange',
//   'mixing',
//   'p2p exchange',
//   'ransomware',
//   'sanctions',
//   'scam',
//   'stolen funds',
//   'stolen bitcoins',
//   'stolen ether',
//   'terrorist financing'
// ];

// export const getCategoryText = category => (categories[category] ? categories[category].text : category);

// export const getCategoryColor = category => (categories[category] ? categories[category].color : '#009688');

// export const alertCategoryOptions = categoryOptions.filter(category => alertCategories.includes(category.key));

// export const chartTitleMap = {
//   deposit: 'Receiving Exposure',
//   withdrawal: 'Sending Exposure'
// };

// export const statusOptions = [
//   {
//     text: 'Open',
//     value: 'OPEN',
//     key: 'OPEN'
//   },
//   {
//     text: 'Closed',
//     value: 'CLOSED',
//     key: 'CLOSED'
//   }
// ];

// export const alertStatusOptions = [
//   {
//     text: 'Unreviewed',
//     value: 'UNREVIEWED',
//     key: 'Unreviewed'
//   },
//   {
//     text: 'In Review',
//     value: 'IN REVIEW',
//     key: 'In Review'
//   },
//   {
//     text: 'Dismissed',
//     value: 'DISMISSED',
//     key: 'Dismissed'
//   },
//   {
//     text: 'Flagged',
//     value: 'FLAGGED',
//     key: 'Flagged'
//   },
//   {
//     text: 'No Review',
//     value: 'NO REVIEW',
//     key: 'No Review'
//   }
// ];

// export const alertStatusColor = {
//   Flagged: dangerColor,
//   Dismissed: primaryColor,
//   Comment: primaryColor,
//   Triggered: 'rgba(0, 0, 0, 0.65)',
//   'In Review': 'rgba(0, 0, 0, 0.65)',
//   'No Review': 'rgba(0, 0, 0, 0.65)',
//   Unreviewed: 'rgba(0, 0, 0, 0.65)'
// };

// export const alertStatusIconColor = {
//   Flagged: 'red',
//   Dismissed: 'blue',
//   Triggered: 'gray',
//   'In Review': 'gray',
//   'No Review': 'gray',
//   Unreviewed: 'gray',
//   Comment: 'blue'
// };

// export const alertStatusDescriptionMap = {
//   Flagged: 'Activity found to be risky',
//   'In Review': 'Activity is currently in review',
//   'No Review': 'Review not needed',
//   Unreviewed: 'Write your comment here',
//   Dismissed: 'Activity found to be non-risky'
// };

// // Misc. helper functions
type DateLike = string | number | Date;

export const formatDateEx = (date: DateLike, format: string, displayTime: boolean) => {
  if (!date) {
    return '—';
  }
  const options = {
    year: 'numeric',
    month: format === 'long' ? 'short' : 'numeric',
    day: 'numeric',
    ...(displayTime && { hour: 'numeric' }),
    ...(displayTime && { minute: 'numeric' }),
    timeZone: 'UTC'
  };

  return new Intl.DateTimeFormat(navigator.language, options).format(new Date(date));
};

export const formatDate = (date: DateLike, displayTime: boolean) => formatDateEx(date, 'long', displayTime);

// export const momentRelativeTime = date => moment(date).fromNow();

// export const momentFormatDate = (date, format) => (date ? moment.utc(date, undefined).format(format) : '—');

// export const getTimeRange = (num, type) => {
//   const timestampGt = moment()
//     .subtract(num, type)
//     .add(1, 'day')
//     .format('YYYY-MM-DDT00:00:00');
//   const timestampLt = moment().format('YYYY-MM-DDT23:59:59');
//   return queryString.stringify({
//     timestamp_gt: timestampGt,
//     timestamp_lt: timestampLt
//   });
// };

type NumberString = string | number;

export const formatNumber = (value: NumberString, format?: string): NumberString => {
  const num = parseFloat(value as string);
  if (Number.isNaN(num)) {
    return '—';
  }

  if (!(num >= 0.0005 || num === 0)) {
    return '< .001';
  }

  switch (format) {
    case 'currency':
      return new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'USD' }).format(num);
    case 'asset':
      return new Intl.NumberFormat(navigator.language, { minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(
        num
      );
    case 'integer':
      return new Intl.NumberFormat(navigator.language, { maximumFractionDigits: 0 }).format(num);
    case 'percent':
      return new Intl.NumberFormat(navigator.language, { minimumFractionDigits: 1, maximumFractionDigits: 1 }).format(
        num
      );
    case 'currencyNoDecimal':
      return new Intl.NumberFormat(navigator.language, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: 'currency',
        currency: 'USD'
      }).format(num);
    default:
      return num;
  }
};

// export const generateTimeSeriesRange = (time, index, end) => {
//   if (index === 0) {
//     return `${moment.unix(time / 1000).format('ll')} - ${moment
//       .unix(time / 1000)
//       .endOf('isoWeek')
//       .format('ll')}`;
//   }
//   if (index === 52) {
//     return `${moment
//       .unix(time / 1000)
//       .startOf('isoWeek')
//       .format('ll')} - ${moment(end).format('ll')}`;
//   }
//   return `${moment
//     .unix(time / 1000)
//     .startOf('isoWeek')
//     .format('ll')} - ${moment
//     .unix(time / 1000)
//     .endOf('isoWeek')
//     .format('ll')}`;
// };

// export const generateTimeSeriesRangeArray = (time, index, end) => {
//   if (index === 0) {
//     return [
//       `${moment.unix(time / 1000).format('YYYY-MM-DDT00:00:00')}`,
//       `${moment
//         .unix(time / 1000)
//         .endOf('isoWeek')
//         .format('YYYY-MM-DDT23:59:59')}`
//     ];
//   }
//   if (index === 52) {
//     return [
//       `${moment
//         .unix(time / 1000)
//         .startOf('isoWeek')
//         .format('YYYY-MM-DDT00:00:00')}`,
//       `${moment(end).format('YYYY-MM-DDT23:59:59')}`
//     ];
//   }
//   return [
//     `${moment
//       .unix(time / 1000)
//       .startOf('isoWeek')
//       .format('YYYY-MM-DDT00:00:00')}`,
//     `${moment
//       .unix(time / 1000)
//       .endOf('isoWeek')
//       .format('YYYY-MM-DDT23:59:59')}`
//   ];
// };

// export const filterObjectEntry = (obj, key) =>
//   Object.keys(obj)
//     .filter(param => !param.includes(key))
//     .reduce((acc, curr) => ({ ...acc, [curr]: obj[curr] }), {});

// export const combineParams = (param1, param2) =>
//   `?${queryString.stringify({ ...queryString.parse(param1), ...queryString.parse(param2) })}`;

// export const removeSortAndOffset = (key, resourceName) =>
//   queryString.stringify(
//     filterObjectEntry(filterObjectEntry(queryString.parse(key.slice(resourceName.length + 1)), 'offset'), 'sort')
//   );

// export const stringifyQueryString = (paramString, defaultSort) => {
//   const params = queryString.parse(paramString);
//   if (!params.sort) {
//     params.sort = `${defaultSort} desc`;
//   }
//   if (!params.offset) {
//     params.offset = '0';
//   }
//   return `?${queryString.stringify(params)}`;
// };

// export const generateNewFilter = currentFilter =>
//   currentFilter.startsWith('indirect')
//     ? currentFilter.slice(8).toLowerCase()
//     : `indirect${currentFilter.charAt(0).toUpperCase() + currentFilter.slice(1)}`;
