import styled from '@emotion/styled';

// https://ant.design/docs/spec/proximity#The-relation-of-vertical-spacing
export enum Sizes {
  small = 0.25,
  medium = 0.5,
  large = 0.75,
}

type SpaceDirection = 'horizontal' | 'vertical';

type SpacerProps = {
  spaceDirection: SpaceDirection;
  size: Sizes;
};

export const Spacer = styled.div((props: SpacerProps) =>
  (props.spaceDirection === 'vertical'
    ? {
      boxSizing: 'border-box',
      marginTop: `${props.size * 2}rem`,
    }
    : {
      boxSizing: 'border-box',
      marginLeft: `${props.size}rem`,
      marginRight: `${props.size}rem`,
      display: 'inline-block',
    }));
