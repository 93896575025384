import React from 'react';
import { Button } from 'antd';
import { css } from '@emotion/core';

type Props = {
  data: Array<string | number>;
  limit: number;
};

const splitAt = (array: Array<string | number>, index: number) => {
  if (array.length <= index) {
    return {
      head: array,
      tail: null,
    };
  }
  return {
    head: array.slice(0, index),
    tail: array.slice(index),
  };
};

const buttonCss = css`
    padding: 0; 
    & > span { 
        text-decoration: underline;
    }
`;

const ExpandableList = ({ data, limit }: Props) => {
  const [expanded, setExpanded] = React.useState(false);

  const { head, tail } = splitAt(data, limit);
  return (
    <>{head.join(', ')}
      {tail && (expanded
        ? <>, {tail.join(', ')}</>
        : (
          <Button type="link" onClick={() => setExpanded(true)} css={buttonCss}>{`+${tail.length} others`}</Button>
        )
      )}
    </>
  );
};

export default ExpandableList;
